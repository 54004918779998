<template>
  <div class="container">
    <el-upload
      class="upload-demo"
      :action="uploadConf.qiniu_upload"
      :file-list="showFileList"
      :show-file-list="true"
      :on-remove="handleRemove"
      :on-success="handleAvatarSuccess"
      :on-error="handleError"
      :before-upload="beforeAvatarUpload"
      :data="uploadConf"
      :limit="1"
      :on-exceed="handleExceed"
    >
      <el-button size="small" type="primary">点击上传</el-button>
      <div slot="tip" class="el-upload__tip">只能上传不超过20M的文件</div>
    </el-upload>
  </div>
</template>

<script>
import { nanoid } from 'nanoid'

export default {
  name: 'QiniuUploaderFile',
  data() {
    return {
      showFileList: [],
      uploadFile: {
        fileName: '',
        fileSize: '',
        fileUrl: '',
        status: ''
      }
    }
  },

  props: {
    uploadConf: {
      type: Object
    },
    uploadResult: {
      type: Function
    },
    fileList: {
      type: Array,
      default() {
        return []
      }
    },
    tips: {
      type: String
    }
  },

  mounted() {},
  methods: {
    beforeAvatarUpload: function(file) {
      this.uploadConf.key = nanoid()
      const isLt2M = file.size / 1024 / 1024 < 20
      if (!isLt2M) {
        this.$message.error('文件大小不能超过 20MB!')
        return false
      }
    },
    handleAvatarSuccess: function(res, file) {
      // this.fileList.push({
      //   uid: file.uid,
      //   name: file.name,
      //   url: this.uploadConf.domain + '/' + res.key
      // })
      this.showFileList.push({
        uid: file.uid,
        name: file.name,
        url: this.uploadConf.domain + '/' + res.key
      })
      this.$emit('update:fileList', this.showFileList)
      // this.uploadResult(this.fileList)
    },

    handleRemove(file, fileList) {
      // this.fileList = this.fileList.filter(item => item.uid !== file.uid)
      this.showFileList = fileList
      this.$emit('update:fileList', this.showFileList)
      // this.uploadResult(this.fileList)
    },

    handleError: function(res) {
      this.$message.error({
        message: '上传失败',
        duration: 2000,
        type: 'warning'
      })
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`)
    }
  }
}
</script>

<style scode></style>
