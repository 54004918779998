<template>
    <div class="wrapper">
        <div class="left">
            <div class="index_top_nav">
                <img src="./images/index_top_nav.png" alt=""/>
            </div>
            <div :class="['banner', position == 'banner' ? 'select-border' : '']" @click="settingBanner('banner')">
                <el-carousel :interval="2000" height="246px" arrow="never">
                    <el-carousel-item v-for="item in bannerConfigsSort" :key="item.uuid">
                        <!-- <img v-if="item.imageUrl" :src="item.imageUrl" /> -->
                        <img :src="item.url" />
                    </el-carousel-item>
                </el-carousel>
            </div>
            <div class="zixun">
                <img src="./images/index_content.png" alt=""/>
            </div>
        </div>
        <div class="right">
            <el-divider content-position="center">{{ title }}</el-divider>
            <el-empty v-if="!position" description="点击左侧虚线区域进行配置"></el-empty>
            <el-form ref="form" :model="formData" label-width="80px">
                <div v-show="position == 'banner'">
                    <div class="banner">
                        <el-button style="margin-bottom:10px;" type="primary" size="small" icon="el-icon-plus"
                                   @click="addBanner('banner')"
                        >新增一张图片
                        </el-button
                        >
                        <div class="banner-title">
                            <span>序号</span>
                            <span>跳转地址</span>
                            <span>图片（1920 * 650）</span>
                            <span>排序</span>
                            <span>操作 </span>
                        </div>
                        <div class="banner-content" v-for="(banner, i) in bannerConfigsSort" :key="i">
                            <span>{{ i + 1 }}</span>
                            <span>
                              <el-form-item label="" label-width="0">
                                <el-input v-model="banner.go_path" placeholder="请输入" size="small"></el-input>
                              </el-form-item>
                            </span>
                            <span>
                                <FileUpload :url.sync="banner.url"></FileUpload>
                            </span>
                            <span>
                              <el-form-item label="" label-width="0">
                                <el-input v-model.lazy="banner.sort" placeholder="请输入" size="small"></el-input>
                              </el-form-item>
                            </span>
                            <span>
                              <el-link style="margin-right:20px;" type="primary" @click="deleteBanner(banner.uuid, 'banner')">删除</el-link>
                            </span>
                        </div>
                    </div>
                    <el-empty v-if="false" description="暂无图片"></el-empty>
                </div>
                <el-form-item v-show="position" label="" label-width="0" style="margin-top:20px;text-align:center">
                    <el-button type="" size="small" @click="getBannerList">取消</el-button>
                    <el-button type="primary" size="small" @click="save">提交</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import {getBannerListAPI, saveSettingAPI} from './api'
import FileUpload from '@/components/file-upload/index.vue'
import { nanoid } from 'nanoid'

export default {
    name: 'decorate-banner',
    components: {FileUpload},
    data() {
        return {
            bannerConfigs: [
                {
                    "url":"",
                    "go_path":"",
                    "sort":"10",
                    "uuid":nanoid()
                }
            ],

            formData: {
                type: 'pc_index_banner',
                banner: [
                ]
            },
            position: 'banner'
        }
    },
    computed: {
        bannerConfigsSort() {
            let dd = this.bannerConfigs
            return dd.sort((pre, next) => {
                let comparison = 0;

                if (parseInt(pre.sort) > parseInt(next.sort)) {
                    comparison = 1;
                } else if (parseInt(pre.sort) < parseInt(next.sort)) {
                    comparison = -1
                }
                return comparison
            })
        },
        title() {
            let title = ''
            if (!this.position) {
                title = '内容设置'
            } else if (this.position == 'banner') {
                title = 'banner图设置'
            }
            return title
        }
    },
    mounted() {
        this.getBannerList()
    },

    methods: {
        async getBannerList() {
            this.bannerConfigs = await getBannerListAPI({type: 'pc_index_banner'})
        },
        // 新增一张banner图
        addBanner(position) {
            let data = {url:"",go_path:"",sort:"10",uuid:nanoid()}
            if (position == 'banner') {
                this.bannerConfigs.push(data)
            }
        },
        // 删除一张banner图
        deleteBanner(uuid, position) {
            if (position == 'banner') {
                this.bannerConfigs = this.bannerConfigs.filter(item => {
                    return item.uuid != uuid
                })
            }
        },
        // 选择区域进行设置
        settingBanner(position) {
            if (!this.position) this.getBannerList()
            this.position = position
        },
        getIndex(position) {
            let index = 0
            this.formData.positionList.forEach((item, i) => {
                if (item.positionCode == position) {
                    index = i
                }
            })
            return index
        },
        // 提交
        save() {
            this.formData.type = 'mini_index'
            let data = {
                type: 'pc_index_banner',
                data: this.bannerConfigsSort
            }
            saveSettingAPI(data).then(() => {
                this.$message.success('保存成功')
                this.getBannerList()
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .left {
    margin-right: 40px;
    width: 775px;
    min-height: 600px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);

    .index_top_nav {
      height: 30px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .banner {
      cursor: pointer;
      margin-top: 10px;
      height: 246px;
      border: 2px dashed #409eff;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .zixun {
      margin-top: 10px;
      padding: 0 10px;
      height: 42px;

      img {
        width: 100%;
      }
    }

    .course {
      height: 172px;
      margin: 10px;

      img {
        width: 100%;
      }
    }

    .yaoqing {
      margin: 10px 0;
      height: 69px;

      img {
        width: 100%;
      }
    }

    .coupon {
      height: 312px;

      img {
        width: 100%;
      }
    }

    .footer {
      margin-top: 20px;
      height: 90px;

      img {
        width: 100%;
      }
    }

    .select-border {
      border: 2px dashed #f56c6c !important;
    }
  }

  .right /deep/ {
    flex: 1;
    min-height: 600px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);

    .el-form {
      padding: 0 10px;

      .el-form-item {
        margin-bottom: 0;
      }

      .banner {
        .banner-title {
          background-color: #f4f5f9;
          padding: 10px 20px;
          display: flex;

          & > span {
            padding-right: 10px;
            flex: 2;

            &:nth-last-child(2) {
              flex: 2;
            }

            &:last-child,
            &:first-child {
              flex: 0.6;
            }
          }
        }

        .banner-content {
          display: flex;
          background-color: #fbfbfb;
          padding: 10px 20px;
          border-bottom: 1px solid #e4e5e9;

          & > span {
            padding-right: 10px;
            flex: 2;
            display: flex;
            align-items: center;

            &:nth-last-child(2) {
              flex: 2;
            }

            &:last-child,
            &:first-child {
              flex: 0.6;
            }
          }
        }
      }
    }

    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 60px !important;
      height: 60px !important;
      line-height: 60px !important;
      text-align: center;
    }

    .avatar {
      width: 60px !important;
      height: 60px !important;
      display: block;
    }
  }
}
</style>
